import React, { useState, useEffect } from 'react'
import { FaPlusCircle, FaEnvelope, FaChartLine } from 'react-icons/fa'
import Link from 'next/link'

import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import Features from '../components/features'

const HeroImageContainerComponent = () => {
  const [imageName, setImageName] = useState('')
  
  useEffect(() => {
    const rando = Math.random() * 200
    if (rando < 50) {
      setImageName('woman-mountains')
    } else if (rando > 50 && rando < 100) {
      setImageName('biker')
    } else if (rando > 100 && rando < 150) {
      setImageName('mtn-runners')
    } else {
      setImageName('justin-running-hill')
    }
  }, [])

  return <div className='absolute w-screen h-screen'>
    {Boolean(imageName) && 
      <div className='relative isolate'>
        <div className='flex items-end h-[580px] md:h-[850px]'>
          <picture >
            <source srcSet={`/hero/${imageName}-sm.webp 500w, /hero/${imageName}-md.webp 1000w, /hero/${imageName}-lg.webp 2000w`} width='auto' type='image/webp' />
            <source srcSet={`/hero/${imageName}-sm.png 500w, /hero/${imageName}-md.png 1000w, /hero/${imageName}-lg.png 2000w`} width='auto' type='image/png' />
            <img src={`/hero/${imageName}-sm.webp`} alt='Challenge Hound - Create and track group challenges' height={1448} width={631} className='w-screen max-h-[850px]' />
          </picture>
        </div>
      </div>
    }
  </div>
} 

const Index = () => {
  return (
    <Layout
      title='Challenge Hound: The Best Virtual Challenge Platform'
      description='Challenge Hound is a virtual challenge platform. It is an awesome way to create and track virtual challenges and races. Create team or individual challenges for running, biking, yoga, swimming, and more.'
      keywords='challenge platform virtual race races running biking cycling walking yoga meditation health group tracking track wellness'>
      <Header section='public' subsection='home' />
      <main>
        <HeroImageContainerComponent />
        
          
        <div className='isolate mt-12 md:mt-32 w-screen h-[600px] md:h-[900px]'>
          <h1 className='text-2xl md:text-4xl text-center font-retro'>Virtual Challenge Platform</h1>
          <h3 className='text-gray-500 text-lg md:text-xl text-center px-10 md:px-0'>Use Challenge Hound to Create and Track Virtual Challenges &amp; Races</h3>
          
          <div className='grid grid-cols-1 md:grid-cols-3 gap-2 max-w-[800px] ml-auto mr-auto mt-12'>
            <div className='mb-4'>
              <Link href={`/houndroid`} as={`/houndroid`} >
                <FaPlusCircle className='text-primary ml-auto mr-auto' style={{ fontSize: 28 }} />
                <h4 className='text-2xl text-center'>Create Challenge</h4>
              </Link>
            </div>
            <div className='mb-4'>
              <Link href={`/challenges`} as={`/challenges`}>
                <FaEnvelope className='text-primary ml-auto mr-auto' style={{ fontSize: 28 }} />
                <h4 className='text-2xl text-center'>Invite Participants</h4>
              </Link>
            </div>
            <div>
              <Link href={`/challenges`} as={`/challenges`}>
                <FaChartLine className='text-primary ml-auto mr-auto' style={{ fontSize: 28 }} />
                <h4 className='text-2xl text-center'>Track Progress</h4>
              </Link>
            </div>
          </div>
        </div>
        
        <Features />

      </main>
      <Footer />
    </Layout>
  );
}

export default Index
